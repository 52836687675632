.main-image {
    margin-bottom: 30px;
}

.main-image img {
    display: inline-block;
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
